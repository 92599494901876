import * as React from "react";
import styled from "@emotion/styled";

import ForestBackgroundImage from "../components/ForestBackgroundImage";
import Main from "../components/Main";
import Layout from "../components/Layout";

import texts from "./texts";

const Heading = styled.h1`
  margin: 0;
  padding: 0 1em;
  font-weight: 400;
  font-size: 2.5em;
  text-shadow: rgba(0, 0, 0, 0.75) 0px 1px 0.2em;
`;

const Paragraph = styled.p`
  padding: 0 1em;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.45em;
  text-shadow: rgba(0, 0, 0, 0.75) 0px 1px 0.2em;
`;

const Card = styled.div`
  background-color: rgba(3, 40, 40, 0.82);
  display: block;
  padding: 1.5em 2em 1em 2em;

  width: 90%;

  @media only screen and (min-width: 480px) {
      width: 85%
    }
    @media only screen and (min-width: 800px) {
        width: 75%
    }
    @media only screen and (min-width: 1200px) {
        width: 65%
    }
    @media only screen and (min-width: 1201px) {
      width: 50%;
    }
`;

const SuccessPage = (props) => {
  const { lang } = props;
  return (
    <Main>
      <ForestBackgroundImage>
        <Layout lang={lang}>
          <Card>
            <Heading>{texts[lang].successPage.heading}</Heading>
            <Paragraph>{texts[lang].successPage.paragraph}</Paragraph>
          </Card>
        </Layout>
      </ForestBackgroundImage>
    </Main>
  );
};

export default SuccessPage;
